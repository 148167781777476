import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../helpers/QueryKeys';
import { getVehicleInfo } from '../api/vehicle-info';

const useVehicleInfo = (value: string) => {
  const query = useQuery([QueryKeys.VehicleInfo, value], () => getVehicleInfo(value), {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
  });

  return {
    ...query,
    order: query.data,
  };
};

export default useVehicleInfo;
