import { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  HomeAccordionWrapper,
  HomeAccordionTitle,
  HomeAccordionTitleItem,
  HomeAccordionTitleIcon,
  HomeAccordionIconContainer,
  HomeAccordionContent,
  HomeAccordionActualContent,
} from './HomeAccordion.styles';

const HomeAccordion = ({ title, content, overflow }) => {
  const [isActive, setIsActive] = useState(false);

  const [overflowState, setOverflowState] = useState('hidden');

  useEffect(() => {
    if (overflow) {
      if (isActive) {
        return setOverflowState('visible');
      }
      setOverflowState('hidden');
    }
  }, [isActive]);

  return (
    <HomeAccordionWrapper>
      <HomeAccordionTitle onClick={() => setIsActive(!isActive)}>
        <HomeAccordionTitleItem>{title}</HomeAccordionTitleItem>
        <HomeAccordionTitleIcon>
          <HomeAccordionIconContainer transform={isActive ? 'rotate(180deg)' : undefined}>
            <KeyboardArrowDownIcon fontSize="inherit" />{' '}
          </HomeAccordionIconContainer>
        </HomeAccordionTitleIcon>
      </HomeAccordionTitle>
      <HomeAccordionContent overflow={overflowState} visibility={isActive ? '500px' : 0}>
        <HomeAccordionActualContent>{content}</HomeAccordionActualContent>
      </HomeAccordionContent>
    </HomeAccordionWrapper>
  );
};

export default HomeAccordion;
