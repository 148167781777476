import React, { useState, useEffect } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import styles from '../../../../scss/_variables.scss';
import {
  Toevoegen4Button,
  Toevoegen4Container,
  Toevoegen4InfoP,
  Toevoegen4InfoP2,
  Toevoegen4MainWrapper,
  Toevoegen4OpslagplaatsInput,
  Toevoegen4OpslagplaatsInput2,
  Toevoegen4SelectItem,
  Toevoegen4SelectItem2,
  Toevoegen4SelectItem2Wrapper,
  Toevoegen4SelectWrapper,
  Toevoegen4Subtitle,
  Toevoegen4Subtitle2,
  Toevoegen4SubtitleP,
  Toevoegen4SubtitleWrapper,
  Toevoegen4Title,
} from './Toevoegen4.styles';

const Toevoegen4 = ({
  setToevoegenStep,
  tyreStatus,
  setTyreStatus,
  priceIfAvailable,
  setPriceIfAvailable,
  optionalStoragePlace,
  setOptionalStoragePlace,
  isClicked,
  setIsClicked,
}) => {
  const [buttonActive4, setButtonActive4] = useState(false);

  const handleClick = () => {
    if (buttonActive4) {
      setToevoegenStep(4);
    }
  };

  // validation
  useEffect(() => {
    if (
      (tyreStatus === 'FOR_SALE' &&
        (priceIfAvailable === 0 ||
          priceIfAvailable === '0' ||
          priceIfAvailable[0] === '0' ||
          priceIfAvailable === '')) ||
      tyreStatus === ''
    ) {
      setButtonActive4(false);
    } else {
      setButtonActive4(true);
    }
  }, [tyreStatus, priceIfAvailable]);

  return (
    <Toevoegen4Container backgroundColor={styles.white}>
      <Toevoegen4MainWrapper>
        <Toevoegen4Title>Opslaan als</Toevoegen4Title>
        <Toevoegen4SelectWrapper>
          {isClicked === 0 || isClicked === -1 ? (
            <Toevoegen4SelectItem
              onClick={(event) => {
                setIsClicked(1);
                if (event.target.innerText === 'Advertentie publiceren') {
                  setTyreStatus('FOR_SALE');
                }
              }}
            >
              <RadioButtonUncheckedIcon
                style={{
                  color: styles.greyNormal,
                  marginLeft: '15px',
                  marginRight: '20px',
                }}
              />
              Advertentie publiceren
            </Toevoegen4SelectItem>
          ) : (
            <Toevoegen4SelectItem2 border={styles.primaryColor} backgroundColor={styles.primaryColorContact}>
              <Toevoegen4SelectItem2Wrapper>
                <RadioButtonCheckedIcon
                  style={{
                    color: styles.primaryColor,
                    marginLeft: '15px',
                    marginRight: '20px',
                  }}
                />
                Advertentie publiceren
              </Toevoegen4SelectItem2Wrapper>
              <Toevoegen4Subtitle2>Verkoopprijs</Toevoegen4Subtitle2>
              <Toevoegen4OpslagplaatsInput2
                focus={styles.primaryColor}
                value={priceIfAvailable}
                min={0}
                onChange={(event) => {
                  const properPriceFormat = event.target.value.match(/^(\d*\.{0,2}\d{0,2}$)/);
                  if (properPriceFormat) {
                    setPriceIfAvailable(event.target.value);
                  }
                }}
                type="number"
              />
              <Toevoegen4InfoP2>Inclusief transportkosten en handelings fee</Toevoegen4InfoP2>
            </Toevoegen4SelectItem2>
          )}
          <Toevoegen4SelectItem
            style={
              isClicked === 0
                ? {
                    backgroundColor: styles.primaryColorContact,
                    border: `1px solid ${styles.primaryColor}`,
                  }
                : {}
            }
            onClick={(event) => {
              setIsClicked(0);
              if (event.target.innerText === 'Opslaan in voorraad') {
                setTyreStatus('INVENTORY');
              }
            }}
          >
            {isClicked === 0 ? (
              <RadioButtonCheckedIcon
                style={{
                  color: styles.primaryColor,
                  marginLeft: '15px',
                  marginRight: '20px',
                }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                style={{
                  color: styles.greyNormal,
                  marginLeft: '15px',
                  marginRight: '20px',
                }}
              />
            )}
            Opslaan in voorraad
          </Toevoegen4SelectItem>
        </Toevoegen4SelectWrapper>
        <Toevoegen4SubtitleWrapper>
          <Toevoegen4Subtitle>Opslagplaats</Toevoegen4Subtitle>
          <Toevoegen4SubtitleP>(optioneel)</Toevoegen4SubtitleP>
        </Toevoegen4SubtitleWrapper>
        <Toevoegen4OpslagplaatsInput
          value={optionalStoragePlace}
          onChange={(event) => setOptionalStoragePlace(event.target.value)}
          focus={styles.primaryColor}
          type="text"
        />
        <Toevoegen4InfoP>Waar op de vestiging ligt de set opgeslagen</Toevoegen4InfoP>
        <Toevoegen4Button
          disabled={!buttonActive4}
          marginTop={isClicked === 0 ? '200px' : '200px'}
          hover={buttonActive4 ? styles.primaryColorContactHoverContent : styles.greyNormal}
          backgroundColor={buttonActive4 ? styles.primaryColor : styles.greyNormal}
          focusBackgroundColor={styles.primaryColor}
          cursor={buttonActive4 ? 'pointer' : 'not-allowed'}
          onClick={() => handleClick()}
        >
          Verder
        </Toevoegen4Button>
      </Toevoegen4MainWrapper>
    </Toevoegen4Container>
  );
};

export default Toevoegen4;
