export const bandenmerken = [
  {
    id: 1,
    value: 'Arrowspeed',
  },
  {
    id: 2,
    value: 'Bridgestone',
  },
  {
    id: 3,
    value: 'Continental',
  },
  {
    id: 4,
    value: 'Dunlop',
  },
  {
    id: 5,
    value: 'Event',
  },
  {
    id: 6,
    value: 'Fulda',
  },
  {
    id: 7,
    value: 'Goodyear',
  },
  {
    id: 8,
    value: 'Hankook',
  },
  {
    id: 9,
    value: 'Laufenn',
  },
  {
    id: 10,
    value: 'Michelin',
  },
  {
    id: 11,
    value: 'Nokian',
  },
  {
    id: 12,
    value: 'Pirelli',
  },
  {
    id: 13,
    value: 'Prestivo',
  },
  {
    id: 14,
    value: 'Security',
  },
  {
    id: 15,
    value: 'Uniroyal',
  },
  {
    id: 16,
    value: 'Vredestein',
  },
  {
    id: 17,
    value: 'Maxxis',
  },
];

export const velgenmerker = [
  {
    id: 1,
    value: '1AV',
  },
  {
    id: 2,
    value: '4Play',
  },
  {
    id: 3,
    value: '79Wheels',
  },
  {
    id: 4,
    value: 'Advanti',
  },
  {
    id: 5,
    value: 'AEZ',
  },
  {
    id: 6,
    value: 'Alfa Romeo',
  },
  {
    id: 7,
    value: 'Alpina',
  },
  {
    id: 8,
    value: 'Alutec',
  },
  {
    id: 9,
    value: 'American Racing',
  },
  {
    id: 10,
    value: 'Anzio',
  },
  {
    id: 11,
    value: 'Arcasting Velgen',
  },
  {
    id: 12,
    value: 'Art-Form',
  },
  {
    id: 13,
    value: 'Asanti Black',
  },
  {
    id: 14,
    value: 'Aston Martin',
  },
  {
    id: 15,
    value: 'ATS',
  },
  {
    id: 16,
    value: 'Audi',
  },
  {
    id: 17,
    value: 'Autec',
  },
  {
    id: 18,
    value: 'Avus',
  },
  {
    id: 19,
    value: 'AXE',
  },
  {
    id: 20,
    value: 'Barotelli',
  },
  {
    id: 21,
    value: 'BBS',
  },
  {
    id: 22,
    value: 'BE Wheels',
  },
  {
    id: 23,
    value: 'Bentley',
  },
  {
    id: 24,
    value: 'Beyern',
  },
  {
    id: 25,
    value: 'Black Rhino',
  },
  {
    id: 26,
    value: 'BMW',
  },
  {
    id: 27,
    value: 'Bola Wheels',
  },
  {
    id: 28,
    value: 'Borbet',
  },
  {
    id: 29,
    value: 'Breyton',
  },
  {
    id: 30,
    value: 'Brock',
  },
  {
    id: 31,
    value: 'Cadillac',
  },
  {
    id: 32,
    value: 'Carmani',
  },
  {
    id: 33,
    value: 'Chevrolet',
  },
  {
    id: 34,
    value: 'Chrysler',
  },
  {
    id: 35,
    value: 'Citroen',
  },
  {
    id: 36,
    value: 'CMS',
  },
  {
    id: 37,
    value: 'Concaver',
  },
  {
    id: 38,
    value: 'Conventry',
  },
  {
    id: 39,
    value: 'Coro Wheels',
  },
  {
    id: 40,
    value: 'CW Wheels',
  },
  {
    id: 41,
    value: 'Dacia',
  },
  {
    id: 42,
    value: 'Daihatsu',
  },
  {
    id: 43,
    value: 'Dezent',
  },
  {
    id: 44,
    value: 'Diewe Wheels',
  },
  {
    id: 45,
    value: 'Dodge',
  },
  {
    id: 46,
    value: 'Dotz',
  },
  {
    id: 47,
    value: 'Elite Wheels',
  },
  {
    id: 48,
    value: 'Enkei',
  },
  {
    id: 49,
    value: 'Eta Beta',
  },
  {
    id: 50,
    value: 'Ferrada',
  },
  {
    id: 51,
    value: 'Ferrari',
  },
  {
    id: 52,
    value: 'Fiat',
  },
  {
    id: 53,
    value: 'Ford',
  },
  {
    id: 54,
    value: 'Forzza',
  },
  {
    id: 55,
    value: 'Fox',
  },
  {
    id: 56,
    value: 'FUEL',
  },
  {
    id: 57,
    value: 'GMP',
  },
  {
    id: 58,
    value: 'Hawke',
  },
  {
    id: 59,
    value: 'Honda',
  },
  {
    id: 60,
    value: 'HRE',
  },
  {
    id: 61,
    value: 'Hummer',
  },
  {
    id: 62,
    value: 'Hyundai',
  },
  {
    id: 63,
    value: 'Infiniti',
  },
  {
    id: 64,
    value: 'Inter Action',
  },
  {
    id: 65,
    value: 'Ispiri',
  },
  {
    id: 66,
    value: 'Isuzu',
  },
  {
    id: 67,
    value: 'IT Wheels',
  },
  {
    id: 68,
    value: 'Iveco',
  },
  {
    id: 69,
    value: 'Jaguar',
  },
  {
    id: 70,
    value: 'Jeep',
  },
  {
    id: 71,
    value: 'JR Wheels',
  },
  {
    id: 72,
    value: 'Keskin',
  },
  {
    id: 73,
    value: 'Kia',
  },
  {
    id: 74,
    value: 'Ispiri',
  },
  {
    id: 75,
    value: 'Koningsrader',
  },
  {
    id: 76,
    value: 'Lamborhgini',
  },
  {
    id: 77,
    value: 'Lancia',
  },
  {
    id: 78,
    value: 'Land Rover',
  },
  {
    id: 79,
    value: 'Lenso',
  },
  {
    id: 80,
    value: 'Lexus',
  },
  {
    id: 81,
    value: 'Lincoln',
  },
  {
    id: 82,
    value: 'Lotus',
  },
  {
    id: 83,
    value: 'MAK',
  },
  {
    id: 84,
    value: 'MAM',
  },
  {
    id: 85,
    value: 'Mandrus',
  },
  {
    id: 86,
    value: 'Maserati',
  },
  {
    id: 87,
    value: 'Mazda',
  },
  {
    id: 88,
    value: 'MB Design',
  },
  {
    id: 89,
    value: 'McLaren',
  },
  {
    id: 90,
    value: 'Meisterwerk',
  },
  {
    id: 91,
    value: 'Mercedes',
  },
  {
    id: 92,
    value: 'MG',
  },
  {
    id: 93,
    value: 'Mille Miglia',
  },
  {
    id: 94,
    value: 'Mini',
  },
  {
    id: 95,
    value: 'Mitsubishi',
  },
  {
    id: 96,
    value: 'Momo',
  },
  {
    id: 97,
    value: 'Monaco',
  },
  {
    id: 98,
    value: 'Motec',
  },
  {
    id: 99,
    value: 'Motegi Racing',
  },
  {
    id: 101,
    value: 'MSW',
  },
  {
    id: 102,
    value: 'Niche',
  },
  {
    id: 103,
    value: 'Nissan',
  },
  {
    id: 104,
    value: 'Opel',
  },
  {
    id: 105,
    value: 'Oxigin',
  },
  {
    id: 106,
    value: 'OZ Racing',
  },
  {
    id: 107,
    value: 'Peugeot',
  },
  {
    id: 108,
    value: 'Platin',
  },
  {
    id: 109,
    value: 'Polestar',
  },
  {
    id: 110,
    value: 'Porsche',
  },
  {
    id: 111,
    value: 'Prestige Auto Couture',
  },
  {
    id: 112,
    value: 'RC Design',
  },
  {
    id: 113,
    value: 'Redbourne',
  },
  {
    id: 114,
    value: 'Renault',
  },
  {
    id: 115,
    value: 'RIAL',
  },
  {
    id: 116,
    value: 'Riviera Wheels',
  },
  {
    id: 117,
    value: 'Rohana',
  },
  {
    id: 118,
    value: 'Rolls Royce',
  },
  {
    id: 119,
    value: 'Ronal',
  },
  {
    id: 120,
    value: 'Rosso',
  },
  {
    id: 121,
    value: 'Rotiform',
  },
  {
    id: 122,
    value: 'Rover',
  },
  {
    id: 123,
    value: 'Saab',
  },
  {
    id: 124,
    value: 'Seat',
  },
  {
    id: 125,
    value: 'Skoda',
  },
  {
    id: 126,
    value: 'Smart',
  },
  {
    id: 127,
    value: 'Soleil',
  },
  {
    id: 128,
    value: 'Sparco',
  },
  {
    id: 129,
    value: 'Spath',
  },
  {
    id: 130,
    value: 'Ssang Young',
  },
  {
    id: 131,
    value: 'Stuttgart',
  },
  {
    id: 132,
    value: 'Subaru',
  },
  {
    id: 133,
    value: 'Suzuki',
  },
  {
    id: 134,
    value: 'TEC',
  },
  {
    id: 135,
    value: 'Tesla',
  },
  {
    id: 136,
    value: 'TMP-Wheels',
  },
  {
    id: 137,
    value: 'Tomason',
  },
  {
    id: 138,
    value: 'Toyota',
  },
  {
    id: 139,
    value: 'TSW',
  },
  {
    id: 140,
    value: 'TUFF',
  },
  {
    id: 141,
    value: 'Veemann',
  },
  {
    id: 142,
    value: 'Victor Equipment',
  },
  {
    id: 143,
    value: 'Volkswagen',
  },
  {
    id: 144,
    value: 'Vorsteiner',
  },
  {
    id: 145,
    value: 'Vossen Wheels',
  },
  {
    id: 146,
    value: 'Wheelforce',
  },
  {
    id: 147,
    value: 'Wheelworld',
  },
  {
    id: 148,
    value: 'XD Series By KMC Wheels',
  },
  {
    id: 149,
    value: 'XO Wheels',
  },
  {
    id: 150,
    value: 'Yanar Wheels',
  },
  {
    id: 151,
    value: 'Yota Wheels',
  },
];
