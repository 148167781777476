import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import useSearchFilter from '../../../hooks/useSearchFilter';
import { FormSelectOptionType } from '../../bhComponents/FormSelectDropdown';

export interface CarFilterType {
  merk: string;
  type: string;
  bouwjaar: string;
}

export const useLicensePlateAddFilter = (
  autoGegevens: CarFilterType,
  hasInputValueBrand: boolean,
  hasInputValueModel: boolean,
  hasInputValueYear: boolean,
  licensePlate: string,
  setLocalBrandValue: Dispatch<SetStateAction<FormSelectOptionType | undefined>>,
  setLocalModelValue: Dispatch<SetStateAction<FormSelectOptionType | undefined>>,
  setLocalYearValue: Dispatch<SetStateAction<FormSelectOptionType | undefined>>
) => {
  const { removeFilter, addFilter, filters } = useSearchFilter(
    useShallow((state) => ({
      filters: state.filters,
      removeFilter: state.removeFilter,
      addFilter: state.addFilter,
    }))
  );

  return useEffect(() => {
    if (
      (filters.has('car_brand') && licensePlate.length === 6) ||
      (autoGegevens.merk !== '' && autoGegevens.merk) ||
      hasInputValueBrand
    ) {
      const filterItem = !hasInputValueBrand ? autoGegevens.merk : filters.get('car_brand');
      addFilter('car_brand', filterItem);
    } else {
      removeFilter('car_brand');
      setLocalBrandValue(undefined);
    }

    if (
      (filters.has('car_model') && licensePlate.length === 6) ||
      (autoGegevens.type !== '' && autoGegevens.type) ||
      hasInputValueModel
    ) {
      const filterItem = !hasInputValueModel ? autoGegevens.type : filters.get('car_model');
      addFilter('car_model', filterItem);
    } else {
      removeFilter('car_model');
      setLocalModelValue(undefined);
    }

    if (
      (filters.has('car_year') && licensePlate.length === 6) ||
      (autoGegevens.bouwjaar !== '' && autoGegevens.bouwjaar) ||
      hasInputValueYear
    ) {
      const filterItem = !hasInputValueYear ? autoGegevens.bouwjaar : filters.get('car_year');
      addFilter('car_year', filterItem);
    } else {
      removeFilter('car_year');
      setLocalYearValue(undefined);
    }

    if (
      (filters.has('car_license') && licensePlate.length === 6) ||
      (licensePlate !== '' && licensePlate && licensePlate.length === 6)
    ) {
      const filterItem = filters.get('car_license') || licensePlate;
      addFilter('car_license', filterItem);
    } else {
      removeFilter('car_license');
    }
  }, [licensePlate, autoGegevens, hasInputValueYear, hasInputValueModel, hasInputValueBrand]);
};

const useHandleChangeInput = (
  setLocal: Dispatch<SetStateAction<string>>,
  setData: Dispatch<SetStateAction<string>>,
  type: string
) => {
  const { removeFilter, addFilter } = useSearchFilter(
    useShallow((state) => ({
      removeFilter: state.removeFilter,
      addFilter: state.addFilter,
    }))
  );

  const handleInput = useCallback(
    (_, option) => {
      setLocal(option);
      setData(option?.value);
      if (option.value) {
        addFilter(type, option.value);
      } else {
        removeFilter(type);
      }
    },
    [removeFilter, addFilter]
  );

  return handleInput;
};

export default useHandleChangeInput;
