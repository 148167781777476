import { useState, useRef, useMemo, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useShallow } from 'zustand/react/shallow';
import { isEmpty } from 'lodash';
import styles from '../../../../scss/_variables.scss';
import LicensePlateInput from '../../LicensePlateInput/LicensePlateInput';
import Button from '../../bhComponents/Button';
import FormControl from '../../bhComponents/FormControl';
import FormBasicSelect from '../../bhComponents/FormBasicSelect';
import HomeAccordion from '../HomeAccordion';
import useSearchFilter from '../../../hooks/useSearchFilter';
import { useGetCheckBoxes } from '../functions/useGetCheckBoxes';
import PriceSlider from '../../form/price-slider';
import ChipButton from '../../bhComponents/ChipButton';
import useHandleChangeInput, { useLicensePlateAddFilter } from '../functions/handleChangeInput';
import useTransformOptions from '../functions/useTransformOptions';
import {
  ZoekenMainWrapper,
  ZoekenTitleWrapper,
  MobileZoekenTitleWrapper,
  MobileZoekenTitleContent,
  MobileZoekenTitleIconWrapper,
  ZoekenSorterenwrapper,
  ZoekenSorterenTextWrapper,
  ZoekenFilterChipWrapper,
  ZoekenSearchButtonWrapper,
  ExpandablesWrapper,
  ZoekenPrijsContentWrapper,
} from './Zoeken.styles';

const sorterenOptions = [
  {
    label: 'Toegevoegd: nieuw naar oud',
    value: 'Toegevoegd: nieuw naar oud',
  },
  {
    label: 'Toegevoegd: oud naar nieuw',
    value: 'Toegevoegd: oud naar nieuw',
  },
  {
    label: 'Prijs: hoog naar laag',
    value: 'Prijs: hoog naar laag',
  },
  {
    label: 'Prijs: laag naar hoog',
    value: 'Prijs: laag naar hoog',
  },
];

const Zoeken = ({
  isSearchMenuOpen,
  isMobileScreen,
  kentekenInputValue,
  setKentekenInputValue,
  autoGegevens,
  setAutogegevens,
  setSearchMenuState,
  merkValue,
  setMerkValue,
  modelValue,
  setModelValue,
  bouwjaarValue,
  setBouwjaarValue,
  sorterenValue,
  setSorterenValue,
  priceRangeValues,
  setPriceRangeValues,
  handleSearchButtonClick,
  filterMerks,
  filterInches,
  filterConditie,
  toastId,
  setToastId,
  toastList,
  setToastList,
  min,
  max,
}) => {
  const { filters, removeFilter, addFilter, resetFilter } = useSearchFilter(
    useShallow((state) => ({
      filters: state.filters,
      removeFilter: state.removeFilter,
      addFilter: state.addFilter,
      resetFilter: state.resetFilter,
    }))
  );

  const [localBrandValue, setLocalBrandValue] = useState({
    label: filters.get('car_brand'),
    value: filters.get('car_brand'),
  });
  const [localModelValue, setLocalModelValue] = useState({
    label: filters.get('car_model'),
    value: filters.get('car_model'),
  });
  const [localYearValue, setLocalYearValue] = useState({
    label: filters.get('car_year'),
    value: filters.get('car_year'),
  });
  const ref = useRef(null);

  const handleBrandChange = useHandleChangeInput(setLocalBrandValue, setMerkValue, 'car_brand');
  const handleModelChange = useHandleChangeInput(setLocalModelValue, setModelValue, 'car_model');
  const handleYearChange = useHandleChangeInput(setLocalYearValue, setBouwjaarValue, 'car_year');

  const { brandOptions, modelOptions, yearOptions } = useTransformOptions(
    localBrandValue?.value || filters.get('car_brand') || '',
    localModelValue || { value: filters.get('car_model') }
  );

  const filtersLocal = [];
  let priceStart = priceRangeValues[0];
  let priceEnd = priceRangeValues[1];

  filters.forEach((value, column) => {
    if (value !== null && value !== undefined) {
      if (!column.startsWith('car')) {
        if (column === 'price_range_start') {
          priceStart = value;
        } else if (column === 'price_range_end') {
          priceEnd = value;
        } else {
          filtersLocal.push({ column, value });
        }
      }
    }
  });

  if (!(priceEnd === priceRangeValues[1] && priceStart === priceRangeValues[0])) {
    const priceValue = `€ ${priceStart} - ${priceEnd}`;
    filtersLocal.push({ column: 'price', value: priceValue });
  }

  const seasonValues = { Zomer: null, 'All season': null, Winter: null };

  const conditionContent = useGetCheckBoxes(filterConditie, 'condition');
  const merkContent = useGetCheckBoxes(filterMerks, 'brand');
  const inchContent = useGetCheckBoxes(filterInches, 'inch');
  const seasonContent = useGetCheckBoxes(seasonValues, 'season');

  const prijsContent = (
    <ZoekenPrijsContentWrapper>
      {typeof min.current === 'number' && typeof max.current === 'number' && (
        <PriceSlider
          priceRangeValues={priceRangeValues}
          setPriceRangeValues={setPriceRangeValues}
          min={min}
          max={max}
        />
      )}
    </ZoekenPrijsContentWrapper>
  );

  const handleChange = useCallback((_, options) => {
    setSorterenValue(options);
  }, []);

  const handleReset = useCallback(() => {
    resetFilter();
    setAutogegevens({ merk: '', type: '', bouwjaar: '' });
    setKentekenInputValue('');
    setLocalBrandValue();
    setLocalModelValue();
    setLocalYearValue();
  }, []);

  const handleDelete = useCallback(
    (_, column, value) => {
      const filterValue = filters?.get(column)?.split(',');

      if (filterValue?.length > 1) {
        const removeValue = filterValue?.filter((item) => item !== value);
        removeFilter(column);
        addFilter(column, removeValue);
      } else {
        removeFilter(column);
        if (column === 'price') {
          removeFilter('price_range_start');
          removeFilter('price_range_end');
        }
      }
    },
    [filters, removeFilter, addFilter]
  );

  const licensePlateData = useMemo(
    () => ({
      make: autoGegevens.merk,
      model: autoGegevens.type,
      year: autoGegevens.bouwjaar,
    }),
    [autoGegevens]
  );

  useLicensePlateAddFilter(
    autoGegevens,
    Boolean(merkValue),
    Boolean(modelValue),
    Boolean(bouwjaarValue),
    kentekenInputValue,
    setLocalBrandValue,
    setLocalModelValue,
    setLocalYearValue
  );

  return (
    <ZoekenMainWrapper
      ref={ref}
      width={isMobileScreen && isSearchMenuOpen ? '100%' : '22.5%'}
      height={isMobileScreen && isSearchMenuOpen ? 'auto' : 'fit-content'}
      display={isMobileScreen && isSearchMenuOpen ? 'flex' : 'none'}
      position={isMobileScreen && isSearchMenuOpen ? 'fixed' : 'relative'}
      background={styles.white}
    >
      {isMobileScreen ? (
        <MobileZoekenTitleWrapper>
          <MobileZoekenTitleContent color={styles.primaryColor}>Zoeken</MobileZoekenTitleContent>
          <MobileZoekenTitleIconWrapper onClick={() => setSearchMenuState(false)}>
            <CloseIcon />
          </MobileZoekenTitleIconWrapper>
        </MobileZoekenTitleWrapper>
      ) : (
        <ZoekenTitleWrapper>Zoeken</ZoekenTitleWrapper>
      )}
      <LicensePlateInput
        handleSearch={handleSearchButtonClick}
        kentekenInputValue={kentekenInputValue}
        setKentekenInputValue={setKentekenInputValue}
        setAutogegevens={setAutogegevens}
        toastId={toastId}
        toastList={toastList}
        setToastList={setToastList}
        setToastId={setToastId}
        brandOptions={brandOptions}
        localBrandValue={localBrandValue}
        handleBrandChange={handleBrandChange}
        modelOptions={modelOptions}
        localModelValue={localModelValue}
        handleModelChange={handleModelChange}
        yearOptions={yearOptions}
        localYearValue={localYearValue}
        handleYearChange={handleYearChange}
        carModelAndYear={licensePlateData}
      />

      <ZoekenSorterenwrapper>
        <ZoekenSorterenTextWrapper>Sorteren</ZoekenSorterenTextWrapper>
        <FormControl inputSize="small" id="sort">
          <FormBasicSelect options={sorterenOptions} onChange={handleChange} value={sorterenValue} />
        </FormControl>
      </ZoekenSorterenwrapper>

      <ZoekenFilterChipWrapper>
        {filtersLocal?.map((filter) =>
          filter?.value
            ?.split(',')
            .map((value) => (
              <ChipButton
                key={value?.toString()}
                label={value?.toString()}
                value={value?.toString()}
                column={filter.column.toString()}
                onClickCross={handleDelete}
              />
            ))
        )}
        {!isEmpty(filtersLocal) && (
          <Button appearance="hollow" size="small" onClick={handleReset}>
            Alle filters wissen
          </Button>
        )}
      </ZoekenFilterChipWrapper>

      <ExpandablesWrapper>
        <HomeAccordion title="Conditie" content={conditionContent} />
        <HomeAccordion title="Seizoen" content={seasonContent} />
        <HomeAccordion title="Merk" content={merkContent} />
        <HomeAccordion title="Prijs" content={prijsContent} overflow />
        <HomeAccordion title="Inch" content={inchContent} />
      </ExpandablesWrapper>

      <ZoekenSearchButtonWrapper>
        <Button fullwidth onClick={handleSearchButtonClick}>
          Zoeken
        </Button>
      </ZoekenSearchButtonWrapper>
    </ZoekenMainWrapper>
  );
};

export default Zoeken;
