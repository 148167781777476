import { useMutation } from '@tanstack/react-query';

import { useCallback } from 'react';
import { getHomePageResults } from 'app/api/homepage';

interface HomepageSearchForm {
  id: number;
  page: number;
  merk: string;
  model: string;
  bouwjaar: string;
  sortField: string;
  ascending: boolean | string;
  condition: Array<string>;
  inches: Array<string>;
  brand: Array<string>;
  season: Array<string>;
  priceRangeStart: number;
  priceRangeEnd: number;
}

const useHomePage = () => {
  const homepageSearchMutation = useMutation(getHomePageResults);

  const onSearchClick = useCallback((data: HomepageSearchForm) => homepageSearchMutation.mutate(data), []);

  const onClearErrorMessage = () => homepageSearchMutation.reset();

  const { isLoading, isError, error, data } = homepageSearchMutation;

  return {
    homepageSearchMutation,
    onSearchClick,
    onClearErrorMessage,
    isLoading,
    isError,
    error,
    data,
  };
};

export default useHomePage;
