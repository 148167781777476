import { forwardRef } from 'react';
import CheckboxBase, { CheckboxBaseProps } from '../CheckboxBase';
import useClassNames from 'app/helpers/useClassNames';
import './Checkbox.scss';

export type CheckboxProps = CheckboxBaseProps;

const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>((props: CheckboxProps, ref) => {
  const { className, ...rest } = props;

  const { merge, withClassPrefix } = useClassNames('checkbox');
  const classes = merge(className, withClassPrefix());

  return <CheckboxBase {...rest} ref={ref} className={classes} />;
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
