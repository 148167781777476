import { forwardRef, MouseEvent, useCallback, useMemo, KeyboardEvent, EventHandler, CSSProperties } from 'react';
import IconButton from '../IconButton';
import IconKruis from '../../../../assets/svg/IconKruis';
import useClassNames from 'app/helpers/useClassNames';
import './ChipButton.scss';

export interface ChipButtonProps {
  className?: string;
  style?: CSSProperties;
  /**
   * Title of chip
   */
  label: string;
  /**
   * Tabindex for IconButton
   */
  tabIndex?: number;
  /**
   * Onclick on whole chip
   */
  onClick?: EventHandler<MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>>;
  /**
   * Onclick on IconButton
   */
  onClickCross?: (event: MouseEvent<HTMLButtonElement>, column: string, value: string) => void;
  disabled?: boolean;
  value: string;
  column: string;
}
const ChipButton = forwardRef<HTMLDivElement, ChipButtonProps>((props: ChipButtonProps, ref) => {
  const { className, label, onClickCross, onClick, style, tabIndex, disabled, value, column } = props;
  const { prefix, withClassPrefix, merge } = useClassNames('chip-button');
  const classes = merge(className, withClassPrefix({ pointer: Boolean(onClick), disabled }));
  const { classCross, classText } = useMemo(
    () => ({
      classCross: prefix('cross'),
      classText: prefix('text'),
    }),
    [prefix]
  );

  const handleClickCross = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (onClickCross) {
        event.stopPropagation();
        onClickCross(event, column, value);
      }
    },
    [onClickCross, value]
  );
  const roleButtonProps = useMemo(() => {
    if (!onClick || disabled) {
      return {};
    }

    return {
      role: 'button',
      tabIndex: tabIndex || 0,
      onClick,
    };
  }, [onClick, tabIndex, disabled]);

  return (
    <div {...roleButtonProps} ref={ref} className={classes} style={style}>
      <div className={classText}>{label}</div>
      {!disabled && (
        <IconButton
          appearance="clear"
          tabIndex={onClickCross ? 0 : -1}
          onClick={handleClickCross}
          icon={<IconKruis className={classCross} />}
        />
      )}
    </div>
  );
});

ChipButton.displayName = 'ChipButton';

export default ChipButton;
