import { useMemo } from 'react';
import useBrands from '../../../hooks/useBrands';
import useModels from '../../../hooks/useModels';

const useTransformOptions = (brandValue: string, modelValue: { value: string }) => {
  const { data: brandsData } = useBrands();
  const { data: modelsData } = useModels(brandValue);

  const brandOptions = useMemo(() => {
    if (brandsData?.brands) {
      return brandsData?.brands.map((brand: string) => ({ label: brand, value: brand }));
    } else {
      return [];
    }
  }, [brandsData?.brands]);

  const modelOptions = useMemo(() => {
    if (modelsData?.models) {
      return modelsData?.models.map((modelInfo: { model: string }) => ({
        label: modelInfo.model,
        value: modelInfo.model,
      }));
    } else {
      return [];
    }
  }, [modelsData?.models]);

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear() + 1;
    const tempModelData = modelsData?.models.filter((item: { model: string }) => item.model === modelValue.value)[0];
    const tempYearOptions = [];
    if (tempModelData?.bouwjaar_van) {
      for (let i = tempModelData.bouwjaar_van; i <= (tempModelData.bouwjaar_tot || currentYear); i++) {
        tempYearOptions.push({
          label: i.toString(),
          value: i.toString(),
        });
      }

      return tempYearOptions;
    }
    return [];
  }, [modelValue, modelsData]);

  return {
    brandOptions,
    modelOptions,
    yearOptions,
  };
};

export default useTransformOptions;
