import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AccordionContainer,
  AccordionContent,
  AccordionActualContent,
  AccordionTitle,
  AccordionTitleIcon,
  AccordionTitleItem,
  AccordionIconContainer,
} from './Accordion.styles';

const Accordion = ({ title, content, index, length }) => {
  const [isActive, setIsActive] = useState(false);

  const shouldRenderBottomBorder = index < length - 1;

  return (
    <AccordionContainer borderBottom={shouldRenderBottomBorder && '1px solid #E6E6E6'}>
      <AccordionTitle onClick={() => setIsActive(!isActive)}>
        <AccordionTitleItem>{title}</AccordionTitleItem>
        <AccordionTitleIcon>
          <AccordionIconContainer transform={isActive ? 'rotate(180deg)' : undefined}>
            <KeyboardArrowDownIcon />{' '}
          </AccordionIconContainer>
        </AccordionTitleIcon>
      </AccordionTitle>
      <AccordionContent visibility={isActive ? '500px' : 0}>
        <AccordionActualContent>{content}</AccordionActualContent>
      </AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
