import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const HomeAccordionWrapper = styled.div`
  border-bottom: 1px solid #e6e6e6;
`;

export const HomeAccordionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 0px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 16px;
  }
`;

export const HomeAccordionTitleItem = styled.div`
  cursor: pointer;
`;

export const HomeAccordionTitleIcon = styled.div``;

export const HomeAccordionContent = styled.div`
  font-size: 18px;
  color: #666666;

  transition: max-height 0.3s ease;
  max-height: ${(props) => props.visibility};
  overflow: ${(props) => props.overflow};

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 14px;
  }
`;

export const HomeAccordionActualContent = styled.div`
  padding-bottom: 5px;
`;

export const HomeAccordionIconContainer = styled.div`
  font-size: 18px;
  color: #136ce2;
  margin-right: 9px;
  display: flex;
  transition: 0.3s ease;
  transform: ${(props) => props.transform};
`;
