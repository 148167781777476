import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { isString } from 'lodash';
import CheckboxGroup from '../../bhComponents/CheckboxGroup';
import Checkbox from '../../bhComponents/Checkbox';
import useSearchFilter from '../../../hooks/useSearchFilter';

export interface FilterParameter {
  column: string;
  value: string;
}

export enum Conditions {
  NEW = 'new',
  USED = 'used',
  GOOD = 'good',
}

export const useGetCheckBoxes = (data: { [key: string]: null | string | number }, type: string) => {
  const { addFilter, removeFilter, filters } = useSearchFilter(
    useShallow((state) => ({
      addFilter: state.addFilter,
      removeFilter: state.removeFilter,
      resetFilter: state.resetFilter,
      filters: state.filters,
    }))
  );

  const filtersLocal: FilterParameter[] = [];
  filters.forEach((value, column) => {
    if (value !== null && value !== undefined && isString(value)) {
      filtersLocal.push({ column, value });
    }
  });

  const initialValue = useMemo(
    () => filtersLocal.filter((item) => item.column === type).map((item) => item.value.split(',')) || [],
    [filtersLocal]
  );

  // Flatten the initial value to a string[].
  const flattenedInitialValue = initialValue.flat();

  const [multiValue, setMultiValue] = useState<string[]>(flattenedInitialValue);

  useEffect(() => {
    // to remove check boxes when remove chip
    const removedChips = filters.get(type)?.split(',');
    setMultiValue(removedChips || []);
  }, [filters, removeFilter]);

  const handleChangeMulti = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    setMultiValue((currentState) => {
      const updatedState = isChecked
        ? [...currentState, value]
        : currentState.filter((stateValue) => stateValue !== value);

      return updatedState;
    });

    if (isChecked) {
      addFilter(type, [...multiValue, event.target.value]);
    } else {
      if (multiValue?.length === 1) {
        removeFilter(type);
      } else {
        addFilter(
          type,
          multiValue.filter((stateValue) => stateValue !== event.target.value)
        );
      }
    }
  };

  return useMemo(() => {
    const localData = data ? Object.entries(data).map(([label, amount]) => ({ label, amount })) : [];
    return (
      <div>
        {localData && (
          <CheckboxGroup column placement="start" onChange={handleChangeMulti} value={multiValue}>
            {localData.map((item) => (
              <Checkbox key={item.label} value={item.label}>
                {item.label} {Boolean(item.amount) && `(${item.amount})`}
              </Checkbox>
            ))}
          </CheckboxGroup>
        )}
      </div>
    );
  }, [data, multiValue]);
};
