import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useShallow } from 'zustand/react/shallow';
import useSearchFilter from '../../hooks/useSearchFilter';

const valuetext = (value: number) => `${value}°C`;

interface Props {
  priceRangeValues: Array<number>;
  setPriceRangeValues: React.Dispatch<React.SetStateAction<Array<number | null | undefined>>>;
  min: React.MutableRefObject<never>;
  max: React.MutableRefObject<never>;
}

const PriceSlider = (props: Props) => {
  const { priceRangeValues, setPriceRangeValues, min, max } = props;
  const { addFilter, filters } = useSearchFilter(
    useShallow((state) => ({
      addFilter: state.addFilter,
      filters: state.filters,
    }))
  );

  // resets the input when chip is removed
  useEffect(() => {
    if (!filters.get('price_range_start') && !filters.get('price_range_end')) {
      setPriceRangeValues([0, max.current]);
    }
  }, [filters]);

  // sets initial data of filters
  useEffect(() => {
    if (filters.has('price_range_start') || filters.has('price_range_end')) {
      setPriceRangeValues([
        Number(filters.get('price_range_start')) || 0,
        Number(filters.get('price_range_end') || max.current),
      ]);
    }
  }, []);

  const maxMinDistance = priceRangeValues[1] - priceRangeValues[0];

  const minDistanceCalculator = () => {
    if (maxMinDistance <= 100) {
      return 5;
    } else if (maxMinDistance > 100 && maxMinDistance <= 1000) {
      return 50;
    } else if (maxMinDistance > 1000 && maxMinDistance <= 10000) {
      return 500;
    } else if (maxMinDistance > 1000 && maxMinDistance <= 100000) {
      return 5000;
    } else {
      return 10000;
    }
  };

  const minDistance = minDistanceCalculator();

  const handleChange1 = (_event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      const sliceValue = [Math.min(newValue[0], priceRangeValues[1] - minDistance), priceRangeValues[1]];
      setPriceRangeValues(sliceValue);
      addFilter('price_range_start', Math.min(newValue[0], priceRangeValues[1] - minDistance)?.toString());
    } else {
      const sliceValue = [priceRangeValues[0], Math.max(newValue[1], priceRangeValues[0] + minDistance)];
      setPriceRangeValues(sliceValue);
      addFilter('price_range_end', Math.max(newValue[1], priceRangeValues[0] + minDistance)?.toString());
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = [event.target.value === '' ? null : Number(event.target.value), priceRangeValues[1]];
    setPriceRangeValues(inputValue);
    addFilter('price_range_start', event.target.value);
  };

  const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = [priceRangeValues[0], event.target.value === '' ? undefined : Number(event.target.value)];
    setPriceRangeValues(inputValue);
    addFilter('price_range_end', event.target.value);
  };

  return (
    <div className="price-slider">
      <div id="slider--mui">
        <Box>
          <Slider
            getAriaLabel={() => 'Minimum distance'}
            value={priceRangeValues}
            onChange={handleChange1}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            disableSwap
            min={min.current}
            max={max.current}
          />
        </Box>
      </div>
      <div className="input-field">
        <div className="input-icon">
          <input
            required
            value={priceRangeValues[0]}
            onChange={handleInputChange}
            type="number"
            className="pricerange-min"
            min={min.current}
            max={priceRangeValues[1] - 1 || undefined}
          />
          <i>€</i>
        </div>
        <div className="input-icon">
          <input
            required
            value={priceRangeValues[1]}
            onChange={handleInputChange2}
            type="number"
            className="pricerange-max"
            min={priceRangeValues[0] + 1 || undefined}
          />
          <i>€</i>
        </div>
      </div>
    </div>
  );
};

export default PriceSlider;
