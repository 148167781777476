import { Dispatch, SetStateAction, useCallback, KeyboardEvent, useEffect, useState, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import Kenteken from '../form/kenteken-input';
import FormSelect from '../bhComponents/FormSelect';
import FormLabel from '../bhComponents/FormLabel';
import FormControl from '../bhComponents/FormControl';
import { LicensePlateData } from '../bhComponents/FormInputLicensePlate/FormInputLicensePlate';
import Button from '../bhComponents/Button';

import IconUiArrowMediumBounded from '../../../assets/svg/IconUiArrowMediumBounded';
import { FormSelectOptionEventHandler, FormSelectOptionType } from '../bhComponents/FormSelectDropdown';
import { ToastListType } from '../../helpers/types';
import { CarFilterType } from '../HomeComponents/functions/handleChangeInput';
import useSearchFilter from '../../hooks/useSearchFilter';
import styles from './LicensePlateInput.module.scss';

interface LicensePlateInputProps {
  disabled?: boolean;
  kentekenInputValue: string;
  setKentekenInputValue: Dispatch<SetStateAction<string>>;
  setAutogegevens: Dispatch<SetStateAction<CarFilterType>>;
  toastId: number;
  toastList: ToastListType[];
  setToastList: () => void;
  setToastId: Dispatch<SetStateAction<number>>;
  brandOptions: FormSelectOptionType[];
  localBrandValue: FormSelectOptionType;
  handleBrandChange: FormSelectOptionEventHandler<FormSelectOptionType | undefined>;
  modelOptions: FormSelectOptionType[];
  localModelValue: FormSelectOptionType;
  handleModelChange: FormSelectOptionEventHandler<FormSelectOptionType | undefined>;
  yearOptions: FormSelectOptionType[];
  localYearValue: FormSelectOptionType;
  handleSearch: () => void;
  handleYearChange: FormSelectOptionEventHandler<FormSelectOptionType | undefined>;
  onDisable?: (value: boolean) => void;
  carModelAndYear: LicensePlateData;
}

const LicensePlateInput = (props: LicensePlateInputProps) => {
  const {
    disabled,
    onDisable,
    kentekenInputValue,
    handleSearch,
    setKentekenInputValue,
    setAutogegevens,
    toastId,
    toastList,
    setToastId,
    setToastList,
    brandOptions,
    localBrandValue,
    handleBrandChange,
    modelOptions,
    localModelValue,
    handleModelChange,
    yearOptions,
    carModelAndYear,
    localYearValue,
    handleYearChange,
  } = props;

  const { filters, removeFilter } = useSearchFilter(
    useShallow((state) => ({
      filters: state.filters,
      removeFilter: state.removeFilter,
    }))
  );

  const initialToggle = filters.has('car_brand') && !filters.has('car_license');

  const [showLicenseInput, setShowLicenseInput] = useState(!initialToggle);

  const handleSearchKeyboardEvent = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && kentekenInputValue.length > 5 && handleSearch && !disabled) {
        handleSearch();
      }
    },
    [kentekenInputValue, disabled]
  );

  const handleRemoveCarFilter = useCallback(() => {
    removeFilter('car_brand');
    removeFilter('car_model');
    removeFilter('car_license');
    removeFilter('car_year');
  }, [removeFilter]);

  const handleClickShowManualInput = useCallback(() => {
    setShowLicenseInput(true);
    setAutogegevens({ merk: '', type: '', bouwjaar: '' });
    setKentekenInputValue('');
    handleRemoveCarFilter();
    onDisable?.(true);
  }, []);
  const handleShowLicenseInput = useCallback(() => {
    setShowLicenseInput(false);
    onDisable?.(true);
    handleRemoveCarFilter();
  }, []);

  useEffect(() => {
    if (!showLicenseInput) {
      if (
        localBrandValue?.value &&
        localBrandValue.value !== '' &&
        localModelValue?.value &&
        localModelValue.value !== '' &&
        localYearValue?.value &&
        localYearValue.value !== ''
      ) {
        onDisable?.(false);
      } else {
        onDisable?.(true);
      }
    }
  }, [showLicenseInput, localBrandValue, localModelValue, localYearValue]);

  const { filterBrand, filterModel, filterYear } = useMemo(() => {
    const filterBrandValue = filters.get('car_brand');
    const filterModelValue = filters.get('car_model');
    const filterYearValue = filters.get('car_year');

    const filterBrand = filterBrandValue ? { label: filterBrandValue, value: filterBrandValue } : localBrandValue;
    const filterModel = filterModelValue ? { label: filterModelValue, value: filterModelValue } : localModelValue;
    const filterYear = filterYearValue ? { label: filterYearValue, value: filterYearValue } : localYearValue;

    return {
      filterBrand,
      filterModel,
      filterYear,
    };
  }, [filters, localBrandValue, localModelValue]);

  return (
    <>
      {showLicenseInput ? (
        <div className={styles.block}>
          <Kenteken
            onDisable={onDisable}
            data={carModelAndYear}
            kentekenInputValue={kentekenInputValue}
            setKentekenInputValue={setKentekenInputValue}
            setAutogegevens={setAutogegevens}
            toastId={toastId}
            setToastId={setToastId}
            handleSearch={handleSearchKeyboardEvent}
            toastList={toastList}
            setToastList={setToastList}
          />
          <Button
            type="button"
            className={styles['button-licanse']}
            endAdornment={<IconUiArrowMediumBounded />}
            onClick={handleShowLicenseInput}
            appearance="clear"
          >
            Handmatig invoeren
          </Button>
        </div>
      ) : (
        <div className={styles.grid}>
          <FormControl id="brand">
            <FormSelect
              label={<FormLabel>Merk</FormLabel>}
              options={brandOptions}
              value={filterBrand}
              onChange={handleBrandChange}
            />
          </FormControl>
          <FormControl id="model" disabled={!modelOptions.length && !filterModel}>
            <FormSelect
              label={<FormLabel>Model</FormLabel>}
              options={modelOptions}
              value={filterModel}
              onChange={handleModelChange}
            />
          </FormControl>
          <FormControl id="year" disabled={!yearOptions.length && !filterYear}>
            <FormSelect
              label={<FormLabel>Bouwjaar</FormLabel>}
              options={yearOptions}
              value={filterYear}
              onChange={handleYearChange}
            />
          </FormControl>
          <Button
            className={styles.button}
            endAdornment={<IconUiArrowMediumBounded />}
            onClick={handleClickShowManualInput}
            appearance="clear"
          >
            Kenteken invoeren
          </Button>
        </div>
      )}
    </>
  );
};

export default LicensePlateInput;
