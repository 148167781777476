export enum QueryKeys {
  Bestellingen = 'bestellingen',
  Bestelling = 'bestelling',
  Account = 'account',
  Kenteken = 'kenteken',
  Aankopen = 'aankopen',
  Aankop = 'aankop',
  Favorieten = 'favorieten',
  PriceRange = 'priceRange',
  Breedte = 'breedte',
  Hoogte = 'hoogte',
  Inchmaat = 'inchmaat',
  Product = 'product',
  Image = 'image',
  Labels = 'tyre-labels',
  Brands = 'brands',
  Models = 'models',
  VehicleInfo = 'vehicleInfo',
}
