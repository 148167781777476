import { create } from 'zustand';
import { isString } from 'lodash';

type FiltersState<FilterKeys> = Map<FilterKeys, string | undefined>;

interface FilterStoreState<FilterKeys> {
  filters: FiltersState<FilterKeys>;
  addFilter: (column: FilterKeys, value: string | string[] | undefined) => void;
  removeFilter: (column: FilterKeys) => void;
  resetFilter: () => void;
}

const createUseFilterStore = <FilterKeys>() => {
  const useFilterStore = create<FilterStoreState<FilterKeys>>((set) => {
    const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');

    const initialState: FiltersState<FilterKeys> = new Map();
    urlParams.forEach((value, key) => {
      initialState.set(key as unknown as FilterKeys, value);
    });

    return {
      filters: initialState,
      addFilter: (column, value) => {
        set((state) => {
          const filters = new Map(state.filters);
          const filteredValue = Array.isArray(value) ? value.join(',') : value;
          filters.set(column, filteredValue);

          return { filters };
        });
      },
      removeFilter: (column) => {
        set((state) => {
          const filters = new Map(state.filters);
          filters.set(column, undefined);

          return { filters };
        });
      },
      resetFilter: () => {
        set(() => ({ filters: new Map() }));
      },
    };
  });

  useFilterStore.subscribe((state) => {
    if (typeof window !== 'undefined') {
      const params: Record<string, string> = {};

      state.filters.forEach((value, column) => {
        if (value !== null && value !== undefined && isString(value)) {
          params[column as unknown as string] = value;
        }
      });

      const currentUrl = `${window.location.pathname}${location.search}`;
      const newUrl = `${window.location.pathname}?${new URLSearchParams(params).toString()}`;

      if (newUrl !== currentUrl) {
        window.history.replaceState(history.state, '', newUrl);
      }
    }
  });

  return useFilterStore;
};

export default createUseFilterStore;
