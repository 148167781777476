import apiClient from 'app/axios/api-client';

type GetHomePageResultsPropsType = {
  id: number;
  page: number;
  merk: string;
  model: string;
  bouwjaar: string;
  sortField: string;
  ascending: boolean | string;
  condition: Array<string>;
  brand: Array<string>;
  season: Array<string>;
  inches: Array<string>;
  priceRangeStart: number;
  priceRangeEnd: number;
};

export const getHomePageResults = async (data: GetHomePageResultsPropsType) => {
  const response = await apiClient.post(
    `search/bandenset/${data.page}?sort_field=${data.sortField}&ascending=${data.ascending}&price_range_start=${data.priceRangeStart}&price_range_end=${data.priceRangeEnd}`,

    {
      car: {
        merk: data.merk,
        type: data.model,
        bouwjaar: data.bouwjaar,
      },
      season: data.season,
      condition: data.condition,
      brand: data.brand,
      inches: data.inches,
    }
  );

  return response.data;
};
